body{
  font-family:Montserrat, sans-serif;
  color:#c5c5c5;
}
.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.qContainerTitle{
  padding:10px 10px;
}

.card-body {
  background-color:rgb(255, 255, 255);
  min-height: "100px";
}

label {
  font-weight: bold;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; 
  width: 100vw; */
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:1000;
  background: #495057;
  opacity: 80%;
  /* position: absolute;  display: flex;
  justify-content: center;
  align-items: center;
   width: 100%; height: 100%; top: 0; 
   left: 0; display: block; 
   overflow-y: hidden; 
   overflow-x: hidden;  */
}

 .custom-select {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0px 10px;
  font-size: 1rem;
  font-weight: 400 ;
   line-height: 1.5;
   color:#fff;
   background:  rgb(0, 125, 171);
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.0px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
} 

.custom-select.category, .custom-select.product {
 

  color:#7e7e7e;
 background: rgb(235, 235, 235);
 border: 1px solid #ced4da;

}  

.custom-select::placeholder{
  color:#fff;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

 
input.form-control {
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  outline: 0px;
  appearance: none;
  color:#7e7e7e;
  background: rgb(235, 235, 235);
  border: 1px solid rgb(235, 235, 235);
  border-radius: 0px;
  padding: 0px 10px;
  height: calc(2.25rem + 2px);
  margin-top:9px;
  font-weight: normal;
}
.input-group span.input-group-text{
  height: auto;
  min-height: 40px;
  margin-top: 0.5rem !important;
}

.input-group input.form-control {
  margin-top: 0.5rem !important;
  height: auto;
  min-height: 40px;
  max-width:50px;
}

textarea.form-control {
  box-sizing: border-box;
  font-size: 16px;
  outline: 0px;
  appearance: none;
  color:#7e7e7e;
  background: rgb(235, 235, 235);
  border: 1px solid rgb(235, 235, 235);
  border-radius: 0px;
  padding: 0px 10px;
  margin-top:9px;
  font-weight: normal;
}

.innerForm img{
  width: 60%;
  display: block;
  margin: 10px auto;
}

@media (min-width: 1200px) {
   .innerForm{
    max-width:980px;
    margin:0px auto;
  }  
}

@media (min-width: 1200px) {
  .dashboardForm{
   max-width:95%;
   margin:0px auto;
 }  

  button.ml-2
  {
    margin:3px 0px; float:none;
  }
}

button.btn-custom, button.btn-clone{
  cursor: pointer;
    font-family: inherit;
    backface-visibility: hidden;
    background-color: rgb(0, 123, 194);
     
    transition: all 200ms ease-in-out 0s;
    color: rgb(255, 255, 255);
    height: auto;
    min-height: 27px;
    text-shadow: none;
    letter-spacing: 0px;
    line-height: 18px;
    border-radius: 0px;
    text-transform: uppercase;
    min-width: 93px;
    padding: 2px 5px;
    font-size: 11px;
    font-weight: 500;
}
button.btn-clone{
  background-color: rgb(99,99,99);
}

button.btn-custom:hover, button.btn-clone:hover{
  background: rgb(212, 212, 212);
    border-color: rgb(99, 99, 99);
}


button.btn-clone:hover{
  background: rgb(193, 193, 193);
    border-color: rgb(0, 98, 155);
}

.form-label{
  margin-right:5px;
}


.editButton{
  margin-right:10px;
}

.dashboard tr:nth-of-type(odd) {
  background-color: rgb(245, 245, 245);
}

.dashboard tr:nth-of-type(even) {
  background-color: rgb(255, 255, 255);

}

.dashboard th{
  background: rgb(255, 255, 255);
  width: auto;
  text-align: left;
  text-transform: capitalize;
  border: 0px;
  min-height: 19px;
  height: auto;
  padding: 14px;
}
.dashboard td{
  vertical-align: middle;
  box-sizing: border-box;
  white-space: nowrap;
  text-align: left;
  padding: 5px 14px;
  height: auto;
  min-height: 50px;
  border: 0px;
  color: rgb(46,46,46);

}
.thProjectTitle{
  font-size:1.5em;
}

.input-group button.mt-2{
  border-left:0px;
  margin-top: 0px;;
}
button.ml-2
{
  margin-left:3px; float:left;
}

.tooltipIcon{
  position: relative;
  right:0px;
  float:right;
}

.tooltipIcon i {
  text-align: center;
  border-radius: 50%;
  background-color:  rgb(0, 123, 194);
  color: white;
  font-size: 11px;
  padding: 4px 9px;
  font-style: normal;
  

}

.tooltipIcon i.info {
position: relative;
top:-8px;
left:5px;
margin-right:3px;
  }


  .dashboardForm  .tooltipIcon{
    position: relative;
    right:0px;
    top:-5px;
    float:none;
    width:20%;
  }

  .dashboardForm .tooltipIcon i {
    text-align: center;
    border-radius: 50%;
    background-color:  rgb(0, 123, 194);
    color: white;
    font-size: 13px;
    padding: 2px 6px;
    font-style: normal;
    font-style: italic;
  
  }

  .heroImgCaption{
    font-weight:bold;
    font-size:.75em;
    text-align: center;
  }

  .productNote{
    margin-top: 20px;
    padding:20px 10px;
    border:red 4px solid;
    border-radius:8px;
    color:red;
    text-align: center;
    box-shadow: 2px 5px 5px #7e7e7e;
  }


  .requiredQ:after{
      color:red;
      content: " *";
  }


  .MuiOutlinedInput-root.Mui-error {
    border-color: transparent !important;   
  }